import React, { useState, useEffect } from "react";
import UAParser from "ua-parser-js";

function BrowserInfo() {
	const [systemInfo, setSystemInfo] = useState({
		browser: { name: "未知", version: "未知" },
		os: { name: "未知", version: "未知" },
		device: { type: "未知", model: "未知", vendor: "未知" },
		engine: { name: "未知", version: "未知" },
	});
	const [ipInfo, setIpInfo] = useState({
		ip: "載入中...",
		country: "",
		region: "",
		flag: "",
	});

	useEffect(() => {
		const parser = new UAParser();
		const result = parser.getResult();
		setSystemInfo(result);

		fetch("https://ipapi.co/json/")
			.then((response) => response.json())
			.then((data) => {
				setIpInfo({
					ip: data.ip,
					country: data.country_name,
					region: data.region,
					flag: getFlagEmoji(data.country_code),
				});
			})
			.catch(() =>
				setIpInfo({ ip: "無法獲取", country: "", region: "", flag: "" })
			);
	}, []);

	function getFlagEmoji(countryCode) {
		const codePoints = countryCode
			.toUpperCase()
			.split("")
			.map((char) => 127397 + char.charCodeAt());
		return String.fromCodePoint(...codePoints);
	}

	return (
		<div className="bg-gray-100 rounded-lg shadow-md p-4 my-4 w-full mx-auto text-sm">
			<h2 className="text-lg font-semibold text-gray-800 mb-3">系統資訊</h2>
			<div className="flex justify-between flex-wrap">
				<InfoCard title="瀏覽器">
					<InfoItem label="名稱" value={systemInfo.browser.name} />
					<InfoItem label="版本" value={systemInfo.browser.version} />
					<InfoItem label="引擎" value={systemInfo.engine.name} />
				</InfoCard>
				<InfoCard title="作業系統">
					<InfoItem label="名稱" value={systemInfo.os.name} />
					<InfoItem label="版本" value={systemInfo.os.version} />
					<InfoItem label="IP 位址" value={`${ipInfo.ip} ${ipInfo.flag}`} />
					<InfoItem
						label="地區"
						value={`${ipInfo.country}${
							ipInfo.region ? `, ${ipInfo.region}` : ""
						}`}
					/>
				</InfoCard>
				<InfoCard title="裝置">
					<InfoItem
						label="類型"
						value={systemInfo.device.type || "桌上型電腦"}
					/>
					<InfoItem label="型號" value={systemInfo.device.model} />
					<InfoItem label="製造商" value={systemInfo.device.vendor} />
				</InfoCard>
				<InfoCard title="螢幕">
					<InfoItem label="寬度" value={`${window.screen.width}像素`} />
					<InfoItem label="高度" value={`${window.screen.height}像素`} />
					<InfoItem
						label="色彩深度"
						value={`${window.screen.colorDepth} 位元`}
					/>
				</InfoCard>
			</div>
		</div>
	);
}

function InfoCard({ title, children }) {
	return (
		<div className="bg-white p-2 rounded-md shadow flex-1 mx-1 mb-2">
			<h3 className="text-base font-semibold text-gray-700 mb-1">{title}</h3>
			{children}
		</div>
	);
}

function InfoItem({ label, value }) {
	return (
		<div className="mb-1">
			<p className="text-xs text-gray-600">{label}</p>
			<p className="text-sm font-medium text-gray-800">{value || "不適用"}</p>
		</div>
	);
}

export default BrowserInfo;
