import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import RoomSelect from "./components/RoomSelect/RoomSelect";
import PlayerSelect from "./components/PlayerSelect/PlayerSelect";
import FlvPlayer from "./components/FlvPlayer/FlvPlayer"; // 更新了 FlvPlayer 的引用路径
import ByteplusPlayer from "./components/ByteplusPlayer/ByteplusPlayer";
import HuaweiPlayer from "./components/HuaweiPlayer/HuaweiPlayer";
import BrowserInfo from "./components/BrowserInfo/BrowserInfo";

function App() {
	const [selectedRoom, setSelectedRoom] = useState({ key: "8789D9_FF753C" });
	const [selectedPlayer, setSelectedPlayer] = useState({ key: "flv" });
	const [key, setKey] = useState(0);
	const [isChangingPlayer, setIsChangingPlayer] = useState(false);
	const [isMuted, setIsMuted] = useState(true);

	useEffect(() => {
		setIsChangingPlayer(true);
		const timer = setTimeout(() => {
			setKey((prevKey) => prevKey + 1);
			setIsChangingPlayer(false);
		}, 100); // 100ms 延迟

		return () => clearTimeout(timer);
	}, [selectedPlayer.key]);

	const getStreamUrl = () => {
		if (selectedPlayer.key === "flv") {
			return `https://live.r1chman.tech/app/${selectedRoom.key}.flv`;
		} else if (selectedPlayer.key === "byteplus") {
			return `https://byteplus-pull.r1chman.tech/app/${selectedRoom.key}_transcoded.sdp`;
		} else if (selectedPlayer.key === "huawei") {
			return `https://live.scbsjh.com/app/${selectedRoom.key}`;
		}
		return "";
	};

	const streamUrl = getStreamUrl();

	const handleInteraction = useCallback(() => {
		setIsMuted(false);
	}, []);

	useEffect(() => {
		document.addEventListener("click", handleInteraction);
		document.addEventListener("keydown", handleInteraction);
		return () => {
			document.removeEventListener("click", handleInteraction);
			document.removeEventListener("keydown", handleInteraction);
		};
	}, [handleInteraction]);
	return (
		<div className="App">
			<div className="container mx-auto px-4 py-8 flex flex-col h-full">
				<div className="flex justify-center space-x-4 mb-8">
					<RoomSelect onSelectRoom={setSelectedRoom} />
					<PlayerSelect onSelectPlayer={setSelectedPlayer} />
				</div>

				<BrowserInfo />

				<div className="flex-grow mt-4">
					<div className="w-full md:w-2/3 mx-auto h-full">
						{" "}
						{/* 修改這裡，設置一個固定高度 */} {/* 设置一个固定高度 */}
						{!isChangingPlayer && (
							<>
								{selectedPlayer.key === "flv" && (
									<FlvPlayer
										key={key}
										url={streamUrl}
										isMuted={isMuted}
										setIsMuted={setIsMuted}
									/>
								)}
								{selectedPlayer.key === "byteplus" && (
									<ByteplusPlayer
										key={key}
										roomKey={selectedRoom.key}
										isMuted={isMuted}
										setIsMuted={setIsMuted}
									/>
								)}
								{selectedPlayer.key === "huawei" && (
									<HuaweiPlayer
										key={key}
										roomKey={selectedRoom.key}
										isMuted={isMuted}
										setIsMuted={setIsMuted}
									/>
								)}
							</>
						)}
						{isChangingPlayer && <div>Changing player...</div>}
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
