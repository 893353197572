import React from "react";

function VideoPlayer({
	videoRef,
	isVideoReady,
	error,
	isMuted,
	handleVolumeChange,
}) {
	return (
		<div className="relative w-full h-full">
			<video
				ref={videoRef}
				controls
				autoPlay
				muted={isMuted}
				playsInline
				onVolumeChange={handleVolumeChange}
				className={`w-full h-full object-contain ${
					isVideoReady ? "block" : "hidden"
				}`}
			>
				Your browser does not support the video tag.
			</video>
			{!isVideoReady && !error && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					Loading...
				</div>
			)}
			{error && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500">
					{error}
				</div>
			)}
		</div>
	);
}

export default React.memo(VideoPlayer);
