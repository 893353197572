import React, { useCallback } from "react";
import VideoPlayer from "./VideoPlayer";
import StreamInfo from "./StreamInfo";
import useHuaweiPlayer from "./useHuaweiPlayer";

// 添加以下代碼以解決潛在的全局變量問題
if (typeof globalThis === "undefined") {
	window.globalThis = window;
}
if (typeof define === "undefined") {
	window.define = () => {};
}

function HuaweiPlayer({ roomKey, isMuted, setIsMuted }) {
	const {
		isVideoReady,
		error,
		clientRef,
		playerId,
		initializePlayer,
		mediaStatistic,
		networkQuality,
	} = useHuaweiPlayer(roomKey, isMuted);

	const handleClick = useCallback(() => {
		if (isMuted) {
			setIsMuted(false);
		}
	}, [isMuted, setIsMuted]);

	return (
		<div className="flex flex-col h-full">
			<div className="flex-grow" style={{ aspectRatio: "16/9" }}>
				<VideoPlayer
					playerId={playerId}
					isVideoReady={isVideoReady}
					error={error}
					handleClick={handleClick}
				/>
			</div>
			{isVideoReady && (mediaStatistic || networkQuality) && (
				<div className="mt-2">
					<StreamInfo
						mediaStatistic={mediaStatistic}
						networkQuality={networkQuality}
					/>
				</div>
			)}
		</div>
	);
}

export default React.memo(HuaweiPlayer);
