import React, { useState } from "react";

const players = [
	{ name: "flv", key: "flv" },
	{ name: "byteplus", key: "byteplus" },
	{ name: "huawei", key: "huawei" },
	{ name: "aliyun", key: "aliyun" },
];

function PlayerSelect({ onSelectPlayer }) {
	const [selectedPlayer, setSelectedPlayer] = useState(players[0]);

	const handleChange = (event) => {
		const selected = players.find(
			(player) => player.key === event.target.value
		);
		setSelectedPlayer(selected);
		onSelectPlayer(selected);
	};

	return (
		<div className="w-72 p-6 bg-white rounded-lg shadow-lg">
			<div className="flex items-center justify-between">
				<label
					htmlFor="player-select"
					className="text-sm font-medium text-gray-700"
				>
					選擇播放器
				</label>
				<div className="relative w-40">
					<select
						id="player-select"
						value={selectedPlayer.key}
						onChange={handleChange}
						className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white"
					>
						{players.map((player) => (
							<option key={player.key} value={player.key}>
								{player.name}
							</option>
						))}
					</select>
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
						<svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
							<path
								fillRule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PlayerSelect;
