import React from "react";

function InfoIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="currentColor"
			className="w-4 h-4 ml-1 inline-block"
		>
			<path
				fillRule="evenodd"
				d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

function getNetworkQualityInfo(quality) {
	const qualityMap = {
		0: { text: "未知", color: "text-gray-500" },
		1: { text: "極佳", color: "text-green-500" },
		2: { text: "良好", color: "text-green-400" },
		3: { text: "尚可", color: "text-yellow-500" },
		4: { text: "不佳", color: "text-orange-500" },
		5: { text: "糟糕", color: "text-red-500" },
		6: { text: "斷線", color: "text-red-600" },
	};

	return qualityMap[quality] || qualityMap[0];
}

function StreamInfo({ mediaStatistic, networkQuality }) {
	const networkQualityInfo = getNetworkQualityInfo(networkQuality);

	const infoItems = [
		{
			label: "網路品質",
			value: (
				<span className={networkQualityInfo.color}>
					{networkQualityInfo.text}
				</span>
			),
			description: "目前網路連線品質",
		},
		{
			label: "往返延遲",
			value: networkQuality?.rtt ? `${networkQuality.rtt} 毫秒` : "不適用",
			description: "網路往返延遲時間",
		},
		{
			label: "封包遺失率",
			value: networkQuality?.packetLossRate
				? `${networkQuality.packetLossRate}%`
				: "不適用",
			description: "網路封包遺失率",
		},
		{
			label: "音訊編碼",
			value: mediaStatistic?.audio?.codec || "不適用",
			description: "音訊編碼格式",
		},
		{
			label: "音訊位元率",
			value: mediaStatistic?.audio?.bitRate
				? `${mediaStatistic.audio.bitRate} kbps`
				: "不適用",
			description: "目前音訊位元率",
		},
		{
			label: "音訊封包遺失數",
			value: mediaStatistic?.audio?.packetsLost || "不適用",
			description: "音訊遺失的封包數量",
		},
		{
			label: "音訊抖動",
			value: mediaStatistic?.audio?.jitter
				? `${mediaStatistic.audio.jitter} 毫秒`
				: "不適用",
			description: "音訊抖動時間",
		},
		{
			label: "影像編碼",
			value: mediaStatistic?.video?.codec || "不適用",
			description: "影像編碼格式",
		},
		{
			label: "影像位元率",
			value: mediaStatistic?.video?.bitRate
				? `${mediaStatistic.video.bitRate} kbps`
				: "不適用",
			description: "目前影像位元率",
		},
		{
			label: "影像封包遺失數",
			value: mediaStatistic?.video?.packetsLost || "不適用",
			description: "影像遺失的封包數量",
		},
		{
			label: "影像抖動",
			value: mediaStatistic?.video?.jitter
				? `${mediaStatistic.video.jitter} 毫秒`
				: "不適用",
			description: "影像抖動時間",
		},
		{
			label: "影像幀率",
			value: mediaStatistic?.video?.frameRate || "不適用",
			description: "目前影像幀率",
		},
		{
			label: "影像解析度",
			value:
				mediaStatistic?.video?.width && mediaStatistic?.video?.height
					? `${mediaStatistic.video.width}x${mediaStatistic.video.height}`
					: "不適用",
			description: "目前影像解析度",
		},
	];

	return (
		<div className="bg-gray-100 rounded-lg p-4 text-xs">
			<div className="grid grid-cols-3 gap-x-4 gap-y-2">
				{infoItems.map(({ label, value, description }) => (
					<div key={label} className="flex justify-between items-center">
						<span className="font-semibold flex items-center">
							{label}：
							<span className="group relative">
								<InfoIcon />
								<span className="absolute hidden group-hover:block bg-black text-white p-1 rounded text-xs -mt-1 ml-1 z-10 whitespace-nowrap">
									{description}
								</span>
							</span>
						</span>
						<span className="ml-1">{value}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default React.memo(StreamInfo);
