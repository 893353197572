import React from "react";
import { getHealthStatus, safeNumber } from "./utils";

function InfoIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="currentColor"
			className="w-4 h-4 ml-1 inline-block"
		>
			<path
				fillRule="evenodd"
				d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
				clipRule="evenodd"
			/>
		</svg>
	);
}
function StreamInfo({ info, mediaInfo }) {
	const health = getHealthStatus(info);

	const infoItems = [
		{
			label: "總幀數",
			value: safeNumber(info?.decodedFrames),
			description: "已解碼的總幀數",
		},
		{
			label: "丟棄幀數",
			value: safeNumber(info?.droppedFrames),
			description: "被丟棄的幀數",
		},
		{ label: "FPS", value: info?.fps || "N/A", description: "每秒顯示幀數" },
		{
			label: "速度",
			value: `${safeNumber(info?.speed, 2)} KB/s`,
			description: "目前播放速度",
		},
		{
			label: "載入器類型",
			value: info?.loaderType || "N/A",
			description: "載入器的類型",
		},
		{
			label: "播放器類型",
			value: info?.playerType || "N/A",
			description: "播放器的類型",
		},
		{
			label: "MIME 類型",
			value: mediaInfo?.mimeType || "N/A",
			description: "媒體類型",
		},
		{
			label: "容器",
			value: mediaInfo?.mimeType?.split(";")[0] || "N/A",
			description: "容器格式",
		},
		{
			label: "影片編碼",
			value: mediaInfo?.videoCodec || "N/A",
			description: "影片編碼格式",
		},
		{
			label: "音訊編碼",
			value: mediaInfo?.audioCodec || "N/A",
			description: "音訊編碼格式",
		},
		{
			label: "影片解析度",
			value:
				mediaInfo?.width && mediaInfo?.height
					? `${mediaInfo.width}x${mediaInfo.height}`
					: "N/A",
			description: "影片解析度",
		},
		{
			label: "影片幀率",
			value: mediaInfo?.fps ? `${mediaInfo.fps} fps` : "N/A",
			description: "影片幀率",
		},
		{
			label: "影片資料率",
			value: mediaInfo?.videoDataRate
				? `${mediaInfo.videoDataRate} kbps`
				: "N/A",
			description: "影片資料傳輸率",
		},
		{
			label: "音訊取樣率",
			value: mediaInfo?.audioSampleRate
				? `${mediaInfo.audioSampleRate} Hz`
				: "N/A",
			description: "音訊取樣率",
		},
		{
			label: "音訊資料率",
			value: mediaInfo?.audioDataRate
				? `${mediaInfo.audioDataRate} kbps`
				: "N/A",
			description: "音訊資料傳輸率",
		},
		{
			label: "音訊聲道",
			value: mediaInfo?.audioChannelCount || "N/A",
			description: "音訊聲道數",
		},
		{
			label: "包含音訊",
			value: mediaInfo?.hasAudio ? "是" : "否",
			description: "是否包含音訊",
		},
		{
			label: "包含影片",
			value: mediaInfo?.hasVideo ? "是" : "否",
			description: "是否包含影片",
		},
		{
			label: "關鍵幀索引",
			value: mediaInfo?.hasKeyframesIndex ? "是" : "否",
			description: "是否包含關鍵幀索引",
		},
		{
			label: "色度格式",
			value: mediaInfo?.chromaFormat || "N/A",
			description: "色度取樣格式",
		},
		{
			label: "設定檔",
			value: mediaInfo?.profile || "N/A",
			description: "編碼設定檔",
		},
		{
			label: "等級",
			value: mediaInfo?.level || "N/A",
			description: "編碼等級",
		},
		{
			label: "參考幀",
			value: mediaInfo?.refFrames || "N/A",
			description: "參考幀數量",
		},
		{
			label: "SAR",
			value:
				mediaInfo?.sarNum && mediaInfo?.sarDen
					? `${mediaInfo.sarNum}:${mediaInfo.sarDen}`
					: "N/A",
			description: "取樣長寬比",
		},
	];

	return (
		<div className="bg-gray-100 rounded-lg p-4 text-xs">
			<div className="flex justify-between items-center mb-2">
				<span className="font-bold text-sm">串流健康狀況：</span>
				<span className={`font-bold text-sm ${health.color}`}>
					{health.status}
				</span>
			</div>
			<div className="grid grid-cols-3 gap-x-4 gap-y-2">
				{infoItems.map(({ label, value, description }) => (
					<div key={label} className="flex justify-between items-center">
						<span className="font-semibold flex items-center">
							{label}：
							<span className="group relative">
								<InfoIcon />
								<span className="absolute hidden group-hover:block bg-black text-white p-1 rounded text-xs -mt-1 ml-1 z-10 whitespace-nowrap">
									{description}
								</span>
							</span>
						</span>
						<span className="ml-1">{value}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default React.memo(StreamInfo);
