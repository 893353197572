export function getHealthStatus(info) {
	const fps = parseFloat(info?.fps);
	const speed = parseFloat(info?.speed);

	if (fps >= 25 && speed >= 500) {
		return { status: "Excellent", color: "text-green-500" };
	} else if (fps >= 20 && speed >= 300) {
		return { status: "Good", color: "text-blue-500" };
	} else if (fps >= 15 && speed >= 100) {
		return { status: "Fair", color: "text-yellow-500" };
	} else {
		return { status: "Poor", color: "text-red-500" };
	}
}

export function safeNumber(value, decimals = 0) {
	const num = parseFloat(value);
	return isNaN(num) ? 0 : num.toFixed(decimals);
}

export function calculateFPS(currentStats, previousStats, elapsedTime) {
	if (!previousStats || !currentStats) return "N/A";

	const framesDiff = currentStats.totalFrames - previousStats.totalFrames;
	const fps = (framesDiff / elapsedTime) * 1000;
	return fps.toFixed(2);
}
