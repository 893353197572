import React, { useState } from "react";

const rooms = [
	{ name: "巨星1", key: "8789D9_FF753C" },
	{ name: "巨星2", key: "8D966D_3C2A59" },
	{ name: "巨星3", key: "6B3FB0_40FB41" },
	{ name: "巨星4", key: "0E6D90_24F7C5" },
	{ name: "巨星5", key: "7A4BD6_53C457" },
	{ name: "巨星6", key: "35997F_41AC0C" },
	{ name: "巨星7", key: "E9090C_8B02D8" },
	{ name: "巨星8", key: "33038E_EABD2B" },
	{ name: "巨星9", key: "CDCA0E_671E77" },
	{ name: "巨星10", key: "F73C50_014234" },
];

function RoomSelect({ onSelectRoom }) {
	const [selectedRoom, setSelectedRoom] = useState(rooms[0]);

	const handleChange = (event) => {
		const selected = rooms.find((room) => room.key === event.target.value);
		setSelectedRoom(selected);
		onSelectRoom(selected);
	};

	return (
		<div className="w-72 p-6 bg-white rounded-lg shadow-lg">
			<div className="flex items-center justify-between">
				<label
					htmlFor="room-select"
					className="text-sm font-medium text-gray-700"
				>
					選擇房間
				</label>
				<div className="relative w-40">
					<select
						id="room-select"
						value={selectedRoom.key}
						onChange={handleChange}
						className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white"
					>
						{rooms.map((room) => (
							<option key={room.key} value={room.key}>
								{room.name}
							</option>
						))}
					</select>
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
						<svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
							<path
								fillRule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RoomSelect;
