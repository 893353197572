export function getHealthStatus(info) {
	if (
		!info ||
		typeof info.decodedFrames === "undefined" ||
		typeof info.droppedFrames === "undefined"
	) {
		return { status: "No Data", color: "text-gray-500" };
	} else if (info.decodedFrames === 0) {
		return { status: "No Data", color: "text-gray-500" };
	} else if (info.droppedFrames === 0) {
		return { status: "Excellent", color: "text-green-500" };
	} else if (info.droppedFrames / info.decodedFrames < 0.05) {
		return { status: "Good", color: "text-blue-500" };
	} else if (info.droppedFrames / info.decodedFrames < 0.1) {
		return { status: "Fair", color: "text-yellow-500" };
	} else {
		return { status: "Poor", color: "text-red-500" };
	}
}

export function safeNumber(value, decimals = 0) {
	return typeof value === "number" ? value.toFixed(decimals) : "N/A";
}

export function calculateFPS(currentInfo, previousInfo, elapsedTime) {
	if (
		currentInfo &&
		previousInfo &&
		typeof currentInfo.decodedFrames === "number" &&
		typeof previousInfo.decodedFrames === "number" &&
		elapsedTime > 0
	) {
		const framesDelta = currentInfo.decodedFrames - previousInfo.decodedFrames;
		const fps = (framesDelta / elapsedTime) * 1000; // Convert to frames per second
		return fps.toFixed(2);
	}
	return "N/A";
}
