import { useState, useRef, useCallback, useEffect } from "react";
import flvjs from "flv.js";
import { calculateFPS } from "./utils";

function useFlvPlayer(url, videoRef) {
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [error, setError] = useState(null);
	const [streamInfo, setStreamInfo] = useState(null);
	const [mediaInfo, setMediaInfo] = useState(null);
	const flvPlayerRef = useRef(null);
	const previousStatsRef = useRef(null);
	const lastUpdateTimeRef = useRef(null);

	const updateStreamInfo = useCallback(() => {
		if (flvPlayerRef.current) {
			const currentTime = Date.now();
			const currentStats = flvPlayerRef.current.statisticsInfo;
			const currentMediaInfo = flvPlayerRef.current.mediaInfo;
			console.log("Full mediaInfo object:", currentMediaInfo); // 添加這行

			let fps = "N/A";
			if (
				lastUpdateTimeRef.current !== null &&
				previousStatsRef.current !== null
			) {
				const elapsedTime = currentTime - lastUpdateTimeRef.current;
				fps = calculateFPS(currentStats, previousStatsRef.current, elapsedTime);
			}

			setStreamInfo((prevInfo) => ({
				...prevInfo,
				...currentStats,
				fps: fps,
			}));

			setMediaInfo(currentMediaInfo);

			previousStatsRef.current = currentStats;
			lastUpdateTimeRef.current = currentTime;
		}
	}, []);

	const initializePlayer = useCallback(() => {
		if (flvjs.isSupported() && videoRef.current) {
			flvPlayerRef.current = flvjs.createPlayer({
				type: "flv",
				url: url,
				isLive: true,
				cors: true,
				hasAudio: true,
				hasVideo: true,
			});
			flvPlayerRef.current.attachMediaElement(videoRef.current);
			flvPlayerRef.current.load();

			flvPlayerRef.current.on(flvjs.Events.LOADING_COMPLETE, () => {
				setIsVideoReady(true);
				flvPlayerRef.current.play().catch((e) => {
					console.error("Error playing video:", e);
					setError("Failed to play video: " + e.message);
				});
			});

			flvPlayerRef.current.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
				console.error("FLV player error:", errorType, errorDetail);
				setError(`FLV player error: ${errorType} - ${errorDetail}`);
			});

			videoRef.current.addEventListener("canplay", () => {
				setIsVideoReady(true);
			});
		} else {
			setError("FLV.js is not supported in this browser");
		}
	}, [url]);

	useEffect(() => {
		initializePlayer();
		const intervalId = setInterval(updateStreamInfo, 1000); // 每秒更新一次

		return () => {
			clearInterval(intervalId);
			if (flvPlayerRef.current) {
				flvPlayerRef.current.destroy();
				flvPlayerRef.current = null;
				setIsVideoReady(false);
				setError(null);
				setStreamInfo(null);
			}
		};
	}, [initializePlayer, updateStreamInfo]);

	return { isVideoReady, error, streamInfo, mediaInfo, initializePlayer };
}

export default useFlvPlayer;
