import React from "react";

function VideoPlayer({
	playerRef,
	isVideoReady,
	error,
	isMuted,
	handleVolumeChange,
	changeQuality,
	currentQuality,
}) {
	const qualities = [
		{ definition: "HD", text: "超清" },
		{ definition: "SD", text: "高清" },
		{ definition: "LD", text: "标清" },
	];

	return (
		<div className="relative w-full h-full">
			<div id="byteplus-player"></div>
			{!isVideoReady && !error && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					Loading...
				</div>
			)}
			{error && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500">
					{error}
				</div>
			)}
			<div className="mt-4 flex justify-center space-x-4">
				{qualities.map((quality) => (
					<button
						key={quality.definition}
						onClick={() => changeQuality(quality.definition)}
						className={`px-4 py-2 rounded ${
							currentQuality === quality.definition
								? "bg-blue-500 text-white"
								: "bg-gray-300 text-gray-700"
						} hover:bg-opacity-80`}
					>
						{quality.text}
					</button>
				))}
			</div>
		</div>
	);
}

export default React.memo(VideoPlayer);
