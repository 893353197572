import React from "react";

function VideoPlayer({ playerId, isVideoReady, error, handleClick }) {
	return (
		<div className="relative w-full h-full" onClick={handleClick}>
			<div id={playerId} className="w-full h-full"></div>
			{!isVideoReady && !error && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					Loading...
				</div>
			)}
			{error && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500">
					{error}
				</div>
			)}
		</div>
	);
}

export default React.memo(VideoPlayer);
