import { useState, useRef, useCallback, useEffect } from "react";
import HWLLSPlayer from "../../sdk/HWLLSPlayer";
import md5 from "md5";

function useHuaweiPlayer(roomKey, isMuted) {
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [error, setError] = useState(null);
	const [mediaStatistic, setMediaStatistic] = useState(null);
	const [networkQuality, setNetworkQuality] = useState(null);
	const clientRef = useRef(null);
	const playerId = "huawei-player";

	const isBrowserSupport = useCallback(async () => {
		try {
			const check = await HWLLSPlayer.checkSystemRequirements();
			console.warn("browser isSupport: " + check);
			return check;
		} catch (error) {
			console.error(
				`check browser isSupport error: ${error.getCode()} - ${error.getMsg()}`
			);
			return false;
		}
	}, []);

	const setupEventListeners = useCallback(() => {
		clientRef.current.streamStatistic(true, 1);
		clientRef.current.on("video-start", () => {
			console.log("video-start");
			setIsVideoReady(true);
		});
		clientRef.current.on("audio-start", () => {
			console.log("audio-start");
		});
		clientRef.current.on("Error", (error) => {
			console.error("Playback error:", error);
			setError(`Playback error: ${error.errCode}`);
		});
		clientRef.current.on("media-statistic", (statistic) => {
			setMediaStatistic(statistic);
		});
		clientRef.current.on("network-quality", (quality) => {
			console.log("network-quality", quality);
			setNetworkQuality(quality);
		});
	}, []);

	const generateAuthKey = useCallback((uri) => {
		const key = process.env.REACT_APP_HUAWEI_KEY;
		const timestamp = Math.floor(Date.now() / 1000);
		const rand = Math.random().toString(36).substring(2, 15);
		const uid = "0";
		const sstring = `${uri}-${timestamp}-${rand}-${uid}-${key}`;
		const md5hash = md5(sstring);
		return `${timestamp}-${rand}-${uid}-${md5hash}`;
	}, []);

	const startPlay = useCallback(async () => {
		const baseUrl = `webrtc://live.scbsjh.com/app/${roomKey}`;
		const uri = `/app/${roomKey}.sdp`;
		const authKey = generateAuthKey(uri);
		const url = `${baseUrl}?auth_key=${authKey}`;

		const options = {
			elementId: playerId,
			muted: isMuted,
			autoPlay: true,
		};

		try {
			await clientRef.current.startPlay(url, options);
			console.log("startPlay success");
		} catch (error) {
			console.error("startPlay fail", error);
			setError(`Failed to start playback: ${error.message}`);
		}
	}, [roomKey, isMuted, generateAuthKey]);

	const stopPlay = useCallback(() => {
		if (clientRef.current) {
			try {
				clientRef.current.stopPlay();
				console.log("stopPlay success");
			} catch (error) {
				console.error("stopPlay fail", error);
			}
			clientRef.current.destoryClient();
			clientRef.current = null;
		}
	}, []);

	const initializePlayer = useCallback(async () => {
		try {
			const isSupported = await isBrowserSupport();
			if (!isSupported) {
				setError("Browser is not supported");
				return;
			}

			clientRef.current = HWLLSPlayer.createClient("webrtc");
			setupEventListeners();
			await startPlay();
		} catch (err) {
			console.error("Failed to initialize player:", err);
			setError("Failed to initialize player");
		}
	}, [isBrowserSupport, setupEventListeners, startPlay]);

	useEffect(() => {
		initializePlayer();

		return () => {
			stopPlay();
		};
	}, [initializePlayer, stopPlay]);

	return {
		isVideoReady,
		error,
		clientRef,
		playerId,
		initializePlayer,
		mediaStatistic,
		networkQuality,
	};
}

export default useHuaweiPlayer;
