import { useState, useRef, useCallback, useEffect } from "react";
import { createLivePlayer, register } from "@byteplus/veplayer";
import "@byteplus/veplayer/style";
import { flv, hls, rtm } from "@byteplus/veplayer-plugin";

register([flv, hls, rtm]);

function useByteplusPlayer(roomKey, playerRef) {
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [error, setError] = useState(null);
	const [currentQuality, setCurrentQuality] = useState("HD");

	const initializePlayer = useCallback(() => {
		const playlist = [
			{
				definitions: [
					{
						url: `https://byteplus-pull.r1chman.tech/app/${roomKey}_rghd.sdp`,
						definition: "HD",
						text: { en: "HD", "zh-cn": "超清" },
					},
					{
						url: `https://byteplus-pull.r1chman.tech/app/${roomKey}_rgsd.sdp`,
						definition: "SD",
						text: { en: "SD", "zh-cn": "高清" },
					},
					{
						url: `https://byteplus-pull.r1chman.tech/app/${roomKey}_rgld.sdp`,
						definition: "LD",
						text: { en: "LD", "zh-cn": "标清" },
					},
				],
			},
		];

		createLivePlayer({
			id: "byteplus-player",
			logger: {
				appId: "611609",
			},
			autoplay: true,
			controls: true,
			defaultDefinition: "HD",
			playlist: playlist,
		})
			.then((player) => {
				playerRef.current = player;
				setIsVideoReady(true);
				console.log("VePlayer is successfully created.");
			})
			.catch((err) => {
				console.error("Creating VePlayer failed due to:", err);
				setError("Failed to create player: " + err.message);
			});
	}, [roomKey]);

	const changeQuality = useCallback((quality) => {
		if (playerRef.current) {
			console.log("Changing quality to:", quality);
			playerRef.current
				.switch({ definition: quality })
				.then(() => {
					console.log("Quality switched successfully to:", quality);
					setCurrentQuality(quality);
				})
				.catch((err) => {
					console.error("Failed to switch quality:", err);
					setError("Failed to change quality: " + err.message);
				});
		}
	}, []);

	useEffect(() => {
		initializePlayer();

		return () => {
			if (playerRef.current) {
				playerRef.current.destroy();
				playerRef.current = null;
			}
		};
	}, [initializePlayer]);

	return {
		isVideoReady,
		error,
		initializePlayer,
		changeQuality,
		currentQuality,
	};
}

export default useByteplusPlayer;
