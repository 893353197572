import React, { useEffect, useRef, useCallback } from "react";
import VideoPlayer from "./VideoPlayer";
import StreamInfo from "./StreamInfo";
import useFlvPlayer from "./useFlvPlayer";

function FlvPlayer({ url, isMuted, setIsMuted }) {
	const videoRef = useRef(null);
	const { isVideoReady, error, streamInfo, mediaInfo, initializePlayer } =
		useFlvPlayer(url, videoRef);

	useEffect(() => {
		console.log("Initializing FLV player with URL:", url);
		initializePlayer();
	}, [url, initializePlayer]);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.muted = isMuted;
		}
	}, [isMuted]);

	const handleVolumeChange = useCallback(() => {
		if (videoRef.current) {
			setIsMuted(videoRef.current.muted);
		}
	}, [setIsMuted]);

	return (
		<div className="flex flex-col h-full">
			<div className="flex-grow">
				<VideoPlayer
					videoRef={videoRef}
					isVideoReady={isVideoReady}
					error={error}
					isMuted={isMuted}
					handleVolumeChange={handleVolumeChange}
				/>
			</div>
			{isVideoReady && streamInfo && (
				<div className="mt-2">
					<StreamInfo info={streamInfo} mediaInfo={mediaInfo} />
				</div>
			)}
		</div>
	);
}

export default React.memo(FlvPlayer);
