import React, { useEffect, useRef, useCallback } from "react";
import VideoPlayer from "./VideoPlayer";
import StreamInfo from "./StreamInfo";
import useByteplusPlayer from "./useByteplusPlayer";

function ByteplusPlayer({ roomKey, isMuted, setIsMuted }) {
	const playerRef = useRef(null);
	const {
		isVideoReady,
		error,
		streamInfo,
		mediaInfo,
		initializePlayer,
		changeQuality,
		currentQuality,
	} = useByteplusPlayer(roomKey, playerRef);

	useEffect(() => {
		console.log("Initializing Byteplus player with roomKey:", roomKey);
		initializePlayer();
	}, [roomKey, initializePlayer]);

	const handleVolumeChange = useCallback(() => {
		if (playerRef.current) {
			setIsMuted(playerRef.current.muted);
		}
	}, [setIsMuted]);

	return (
		<div className="flex flex-col h-full">
			<div className="flex-grow" style={{ aspectRatio: "16/9" }}>
				<VideoPlayer
					playerRef={playerRef}
					isVideoReady={isVideoReady}
					error={error}
					isMuted={isMuted}
					handleVolumeChange={handleVolumeChange}
					changeQuality={changeQuality}
					currentQuality={currentQuality}
				/>
			</div>
			{isVideoReady && streamInfo && (
				<div className="mt-2">
					<StreamInfo info={streamInfo} mediaInfo={mediaInfo} />
				</div>
			)}
		</div>
	);
}

export default React.memo(ByteplusPlayer);
